import React from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import Button from "shared/components/Button/index.legacy.js"
import { sm } from "mill/utils/breakpoints"
import { headingFont } from "mill/utils/settings"

const Header = styled.div`
  background: ${props => props.theme.colors.primary};
  color: white;
  padding: 1rem 2rem;
  position: relative;
  text-align: center;

  @media ${sm} {
    padding: 2rem 3rem 10rem;
  }

  a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.4rem;
    font-weight: bold;
  }

  img {
    margin: 16px 0px 12px;
    height: 33px;
  }
`

const Card = styled.div`
  padding: 2rem 3rem 5rem;
  background: white;
  position: relative;
  box-shadow: rgb(0 0 0 / 5%) 1px 4px 10px 0px;

  h1 {
    color: ${props => props.theme.colors.primary};
    font-size: 4rem;
    font-family: ${headingFont};
    padding-bottom: 1rem;
    text-align: center;
  }

  button {
    width: 100%;
  }

  @media ${sm} {
    border-radius: 6px;
    max-width: 500px;
    margin: -8rem auto 0px;
    padding-top: 1rem;
  }
`

const Logout = () => {
  const yarnoLogo = "https://yarno.imgix.net/static/logo.png"
  const talLogo = "https://yarno.imgix.net/static/tal/logo.png"
  const imgSrc = window.THEME_NAME === "tal" ? talLogo : yarnoLogo
  const signBackIn = () => {
    window.location = `/login?redirect_to=${window.location.origin}`
  }

  return (
    <div style={{ width: "100%" }}>
      <Header>
        <a
          href="https://www.yarno.com.au"
          title="Yarno: Confident, capable employees in just 3 minutes a day">
          <img src={imgSrc} alt="Yarno" />
        </a>
      </Header>
      <Card>
        <h1>You've signed out</h1>
        <p>
          We're sad to see you leave, but we hope you'll be back for more
          learning soon!
        </p>
        <Button label="Sign back in" onClick={signBackIn} />
      </Card>
    </div>
  )
}

export default Logout
